.review-dialog-container {
    height: 600px;
    width: 400px;
}

.review-dialog-header {
    position: fixed;
    display: contents;
}

.review-dialog-content {
    overflow: auto;
    height: 465px;
}

.review-dialog-line {
    display: flex;
    justify-content: center;
    white-space: pre-line;
}

.review-dialog-button {
    cursor: pointer;
    color: blue;
}