.review-container {
    border-right-style: solid;
    border-left-style: solid;
    width: 30%;
    margin: auto;
}

.review-content {
    overflow: auto;
    height: 63vh;
}

.review-content-max {
    overflow: auto;
    height: 87vh;
}

.review-line {
    display: flex;
    justify-content: center;
    white-space: pre-line;
}

.review-button {
    cursor: pointer;
    color: blue;
}

.review-reject-button {
    margin-right: 80px;
}

.review-approve-button {
    margin-left: 80px;
}

.review-yes-button {
    margin-right: 50px;
}

.review-no-button {
    margin-left: 50px;
}