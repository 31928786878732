.submission-container {
    height: 600px;
    width: 400px;
}

.submission-header {
    padding: 10px 16px;
    position: fixed;
    display: contents;
}

.submission-header-title {
    margin-top: 10px;
}

.submission-content {
    margin-bottom: 30px;
    height: 420px;
    overflow: auto;
}