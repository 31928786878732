.articles-container {
    text-align: center;
}

.articles-files-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.articles-line {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.articles-element {
    margin-left: 50px;
}

.articles-relative-name {
    width: 800px;
}

.articles-version-number {
    width: 30px;
}

.articles-status {
    width: 150px;
}

.articles-button {
    cursor: pointer;
    color: blue;
}

.articles-modal-container {
    max-height: 70vh;
}

.articles-review-modal-container {
    max-height: 50vh;
    width: 250px;
}